import { downloadSessionReportRequest, getSessionsRequest } from 'api';
import { authActions, sessionsActions } from 'state/reducers';
import { createAppAsyncThunk } from 'utils';

const getSessions = createAppAsyncThunk<any>(
  'sessions/get-sessions',
  async (arg, { dispatch, getState }) => {
    const { sessionsSettings } = getState().sessions;

    try {
      dispatch(authActions.setLoading({ isLoading: true }));

      const res = await getSessionsRequest(sessionsSettings);

      dispatch(sessionsActions.setSessions(res?.data?.sessions));
      dispatch(
        sessionsActions.setSessionsSettings({
          ...sessionsSettings,
          // eslint-disable-next-line no-unsafe-optional-chaining
          totalCount: res?.data?.totalCount,
        }),
      );
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const downloadSessionReport = createAppAsyncThunk(
  'sessions/download-session-report',
  async (
    { sessionId, sessionTitle }: { sessionId: string; sessionTitle: string },
    { dispatch, getState },
  ) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));

      const res = await downloadSessionReportRequest(sessionId);

      if (res && res?.data) {
        const a = document.createElement('a');
        const file = new Blob([res.data], {
          type: 'text/plain',
        });

        // @ts-ignore
        a.href = URL.createObjectURL(file);
        // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
        a.download = sessionTitle;
        a.click();
        a.remove();
      }
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

export const sessionsThunk = { getSessions, downloadSessionReport };
