import React, { useCallback, useId } from 'react';

import { clsx } from 'clsx';
import ReactSelect, {
  Props,
  components,
  ControlProps,
  FormatOptionLabelMeta,
} from 'react-select';

import { CircleCheckboxIcon } from 'assets';
import { Typography } from 'ui-kit';
import './Select.scss';

export type SelectOption = {
  value: string;
  label: string;
  image?: string;
};

type CustomSelectProps = {
  isSearchSelect?: boolean;
  className?: string;
  label?: string;
  isOptionDisabled?: boolean;
  error?: string;
  controlTitle?: string;
};

export const Select = (props: Props<SelectOption> & CustomSelectProps) => {
  const {
    label,
    className,
    isOptionDisabled,
    error,
    controlTitle,
    components: outsideComponents,
    ...rest
  } = props;
  const id = useId();

  const formatOptionLabel = useCallback(
    (
      file: SelectOption,
      { selectValue, context }: FormatOptionLabelMeta<SelectOption>,
    ) => {
      return (
        <div className="selectForm_option">
          <span>{file?.label}</span>
          {context === 'menu' &&
            (selectValue[0]?.label === file?.label ? (
              <CircleCheckboxIcon checked />
            ) : (
              <CircleCheckboxIcon />
            ))}
        </div>
      );
    },
    [],
  );

  const control = useCallback(
    ({ children, ...props }: ControlProps<SelectOption>) => {
      return (
        <components.Control {...props}>
          {controlTitle && (
            <span className="selectForm_controlTitle">{controlTitle}:</span>
          )}
          {children}
        </components.Control>
      );
    },
    [controlTitle],
  );

  return (
    <div
      className={clsx('selectForm', className, {
        selectForm_error: error,
      })}
    >
      {label && (
        <Typography
          variant="h3"
          as="label"
          className="selectForm_label"
          htmlFor={`${id}-select`}
        >
          {label}
        </Typography>
      )}
      <ReactSelect
        classNamePrefix="select"
        menuIsOpen={isOptionDisabled ? false : undefined}
        id={`${id}-select`}
        aria-describedby={`${id}-error-message`}
        components={{ ...outsideComponents, Control: control }}
        formatOptionLabel={formatOptionLabel}
        {...rest}
      />
      {error && (
        <Typography
          variant="p3"
          className="selectForm_error"
          aria-live="polite"
          id={`${id}-error-message`}
        >
          {error}
        </Typography>
      )}
    </div>
  );
};
