import { AxiosResponse } from 'axios';

import { SlotType } from './types';

import { CommonResponseType } from 'api/authApi';
import { authServiceClient } from 'config';

export const getSlotsRequest = () => {
  return authServiceClient.get<SlotType, AxiosResponse<SlotType>>(
    `/manager-slot/slot-users`,
  );
};

export const addStudentToSlotRequest = (email: string) => {
  return authServiceClient.post<SlotType, AxiosResponse<SlotType>, { email: string }>(
    `/manager-slot/add-to-slot`,
    { email },
  );
};

export const deleteSlotRequest = (email: string) => {
  return authServiceClient.post<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    { email: string }
  >(`/manager-slot/remove-from-slot`, { email });
};
