export const routes = {
  AUTH: {
    LOG_IN: '/login',
    SIGN_UP: '/sign-up',
    RESTORE_PASSWORD: '/restore-password',
    CONFIRMATION_CODE: 'confirmation-code',
    NEW_PASSWORD: 'new-password',
    SUCCESS: 'success',
  },
  MAIN: {
    SESSIONS: '/sessions',
    USERS: '/users',
    SLOTS: '/slots',
  },
} as const;
