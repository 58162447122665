import React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { CommonModal } from '../CommonModal';

import { EmailIcon } from 'assets';
import { CommonModalType } from 'common';
import { ControlledTextField } from 'components';
import { emailSchema } from 'config';
import { useActions } from 'hooks';
import { slotsThunk } from 'state';
import { Button, Typography } from 'ui-kit';
import './AddStudentModal.scss';

type FormType = z.infer<typeof emailSchema>;

export const AddStudentModal = ({ showModal, setShowModal }: CommonModalType) => {
  const { addStudent } = useActions(slotsThunk);

  const onCloseClick = () => {
    setShowModal(false);
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormType>({
    resolver: zodResolver(emailSchema),
    defaultValues: { email: '' },
  });
  const onSubmit: SubmitHandler<FormType> = ({ email }) => {
    addStudent({ email: email.trim(), onCloseClick });
  };

  return (
    <CommonModal showModal={showModal} closeModal={onCloseClick}>
      <section className="addStudentModal">
        <Typography variant="h3" className="addStudentModal_h3">
          Add Student
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlledTextField
            label="Email"
            type="text"
            name="email"
            control={control}
            autoComplete="off"
            icon={<EmailIcon className="addStudentModal_icon" />}
            className="addStudentModal_textField"
            placeholder="Enter student email"
          />

          <Button
            type="submit"
            className="addStudentModal_button"
            disabled={isSubmitting}
          >
            Invite
          </Button>
        </form>
      </section>
    </CommonModal>
  );
};
