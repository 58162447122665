import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SessionType } from 'api';
import { SettingsType } from 'common';
import { Pagination } from 'enums';

const initialState = {
  sessions: [] as SessionType[],
  sessionsSettings: {
    take: Pagination.TAKE,
    skip: 0,
    search: '',
  } as SettingsType,
};

const slice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    setSessions: (state, action: PayloadAction<SessionType[]>) => {
      state.sessions = action.payload;
    },
    setSessionsSettings: (state, action: PayloadAction<SettingsType>) => {
      state.sessionsSettings = action.payload;
    },
  },
});

export const sessionsActions = slice.actions;

export const sessionsReducer = slice.reducer;
