import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserType } from 'api';
import { SettingsType } from 'common';
import { Pagination } from 'enums';

const initialState = {
  users: [] as UserType[],
  certainUser: {} as UserType | undefined,
  userSettings: {
    take: Pagination.TAKE,
    skip: 0,
    search: '',
  } as SettingsType,
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<UserType[]>) => {
      state.users = action.payload;
    },
    setCertainUser: (state, action: PayloadAction<UserType | undefined>) => {
      state.certainUser = action.payload;
    },
    setUserSettings: (state, action: PayloadAction<SettingsType>) => {
      state.userSettings = action.payload;
    },
  },
});

export const usersActions = slice.actions;

export const usersReducer = slice.reducer;
