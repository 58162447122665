import { AxiosResponse } from 'axios';

import { SessionsResponseType } from './types';

import { SettingsType } from 'common';
import { authServiceClient } from 'config';

export const getSessionsRequest = (params: SettingsType) => {
  return authServiceClient.get<
    SessionsResponseType,
    AxiosResponse<SessionsResponseType>,
    SettingsType
  >(`/session/all-sessions`, { params: { ...params, totalCount: undefined } });
};

export const downloadSessionReportRequest = (sessionId: string) => {
  return authServiceClient.get<any, AxiosResponse<any>, { sessionId: string }>(
    `/file/files`,
    { params: { sessionId } },
  );
};
