import { z } from 'zod';

import { Roles } from 'enums';

const WRONG_PASSWORD = 'Password must contain from 8 to 16 characters';
const PASSWORDS_NO_MATCH = "Your passwords don't match";
const REQUIRED_FIELD = 'Required field';
const NUMBER_FIELD = 'Field must contain only digits';
const MIN_LENGTH = 'Field must be longer than or equal to 2 characters';
const MAX_LENGTH_50 = 'The field must be shorter than or equal to 50 characters';
const MAX_LENGTH_250 = 'The field must be shorter than or equal to 250 characters';

export const loginSchema = z.object({
  email: z.string().trim().email(),
  password: z
    .string()
    .min(8, { message: WRONG_PASSWORD })
    .max(16, { message: WRONG_PASSWORD }),
});

export const createPasswordSchema = z
  .object({
    password: z
      .string()
      .min(8, { message: WRONG_PASSWORD })
      .max(16, { message: WRONG_PASSWORD }),
    confirmPassword: z.string().min(1, { message: REQUIRED_FIELD }),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.confirmPassword) {
      ctx.addIssue({
        message: PASSWORDS_NO_MATCH,
        code: z.ZodIssueCode.custom,
        path: ['confirmPassword'],
      });
    }

    return data;
  });

export const emailSchema = z.object({
  email: z.string().trim().email(),
});

export const slotSchema = z.object({
  firstName: z
    .string()
    .trim()
    .min(2, { message: MIN_LENGTH })
    .max(50, { message: MAX_LENGTH_50 }),
  lastName: z
    .string()
    .trim()
    .min(2, { message: MIN_LENGTH })
    .max(50, { message: MAX_LENGTH_50 }),
  email: z.string().trim().email(),
  hospital: z
    .string()
    .trim()
    .min(2, { message: MIN_LENGTH })
    .max(250, { message: MAX_LENGTH_250 }),
});

export const userSchema = z.discriminatedUnion('role', [
  z.object({
    role: z.literal(Roles.MANAGER),
    firstName: z
      .string()
      .trim()
      .min(2, { message: MIN_LENGTH })
      .max(50, { message: MAX_LENGTH_50 }),
    lastName: z
      .string()
      .trim()
      .min(2, { message: MIN_LENGTH })
      .max(50, { message: MAX_LENGTH_50 }),
    email: z.string().trim().email(),
    status: z.string().trim(),
    numOfStud: z.coerce.number({ invalid_type_error: NUMBER_FIELD }).nonnegative(),
    university: z
      .string()
      .trim()
      .min(2, { message: MIN_LENGTH })
      .max(250, { message: MAX_LENGTH_250 }),
  }),
  z.object({
    role: z.literal(Roles.STUDENT),
    firstName: z
      .string()
      .trim()
      .min(2, { message: MIN_LENGTH })
      .max(50, { message: MAX_LENGTH_50 }),
    lastName: z
      .string()
      .trim()
      .min(2, { message: MIN_LENGTH })
      .max(50, { message: MAX_LENGTH_50 }),
    email: z.string().trim().email(),
    status: z.string().trim(),
    feedbacks: z.string().trim(),
    incompleteFeedbacks: z.string().trim(),
    hospital: z
      .string()
      .trim()
      .min(2, { message: MIN_LENGTH })
      .max(250, { message: MAX_LENGTH_250 }),
  }),
  z.object({
    role: z.literal(Roles.DOCTOR),
    firstName: z
      .string()
      .trim()
      .min(2, { message: MIN_LENGTH })
      .max(50, { message: MAX_LENGTH_50 }),
    lastName: z
      .string()
      .trim()
      .min(2, { message: MIN_LENGTH })
      .max(50, { message: MAX_LENGTH_50 }),
    email: z.string().trim().email(),
    status: z.string().trim(),
    hospital: z
      .string()
      .trim()
      .min(2, { message: MIN_LENGTH })
      .max(250, { message: MAX_LENGTH_250 }),
    department: z.object({
      value: z
        .string()
        .trim()
        .min(2, { message: MIN_LENGTH })
        .max(250, { message: MAX_LENGTH_250 }),
      label: z
        .string()
        .trim()
        .min(2, { message: MIN_LENGTH })
        .max(250, { message: MAX_LENGTH_250 }),
    }),
  }),
]);
