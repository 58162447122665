import { SVGProps, memo } from 'react';

export const ArrowIcon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M7.40194 2.89709C7.71134 2.43299 8.36797 2.36797 8.76238 2.76238L17.2929 11.2929C17.6834 11.6834 17.6834 12.3166 17.2929 12.7071L8.76238 21.2376C8.36797 21.632 7.71134 21.567 7.40194 21.1029C7.16823 20.7523 7.21445 20.2855 7.51238 19.9876L15.5 12L7.51238 4.01238C7.21445 3.71445 7.16823 3.24766 7.40194 2.89709Z"
      fill="#0B365B"
      rotate={props.rotate}
    />
  </svg>
));
