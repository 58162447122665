import React, { ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';

import { clsx } from 'clsx';

import { SearchIcon } from 'assets';
import './SearchInput.scss';

type DefaultInputPropsType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type ISearchProps = DefaultInputPropsType & {
  className?: string;
  value?: string;
  onChangeText?: (value: string) => void;
  placeholder?: string;
};

export const SearchInput: FC<ISearchProps> = props => {
  const { className, onChangeText, onChange, value, placeholder, ...rest } = props;
  const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
    if (onChangeText) onChangeText(e.currentTarget.value);
  };

  return (
    <div className={clsx('searchInput', className)}>
      <div className="searchInput_inputWrapper">
        <input
          value={value}
          className="searchInput_inputWrapper_input"
          type="text"
          onChange={onChangeCallback}
          placeholder={placeholder}
          {...rest}
        />
        <SearchIcon className="searchInput_inputWrapper_icon" />
      </div>
    </div>
  );
};
