export enum Departments {
  ALLERGE_IMMUNOLOGY = 'Allergy & Immunology',
  ACUTE_INTERNAL_MEDICINE = 'Acute Internal medicine',
  ANAESTHETICS = 'Anaesthetics',
  CARDIOTHORACIC_SURGERY = 'Cardiothoracic surgery',
  CARDIOLOGY = 'Cardiology',
  DERMATOLOGY = 'Dermatology',
  EMERGENCY = 'Emergency',
  ENDOCRINOLOGY = 'Endocrinology',
  EAR_NOSE_THROAT_SURGERY = 'Ear, Nose, and Throat surgery',
  GASTROENTEROLOGY = 'Gastroenterology',
  GENERAL_PARACTICE = 'General Practice',
  GENERAL_SURGERY = 'General surgery',
  GERIATRIC_MEDECINE = 'Geriatric medicine',
  GENITO_URINARY_MEDICINE = 'Genito-urinary medicine',
  HAEMATOLOGY = 'Haematology',
  HISTOPATHOLOGY = 'Histopathology',
  INFECTIUS_DISEASES = 'Infectious diseases',
  METABOLIC_MEDECINE = 'Metabolic medicine',
  MICROBIOLOGY = 'Microbiology',
  NEUROLOGY = 'Neurology',
  NEUROSURGERY = 'Neurosurgery',
  OBSTETRICS_AND_GYNAECOLOGY = 'Obstetrics and gynaecology',
  ONCOLOGY = 'Oncology',
  OPHTHALMOLOGY = 'Ophthalmology',
  ORAL_AND_MAXILAA_FACIAL_SURGERY = 'Oral and maxilla-facial surgery',
  PAEDIATRIS = 'Paediatrics',
  PALLIATIVE_CARE = 'Palliative care',
  PHARMOCOLOGY = 'Pharmacology',
  PLASTIC_SURGERY = 'Plastic surgery',
  PSYCHIATRY = 'Psychiatry',
  RADIOLOGY = 'Radiology',
  RENAL = 'Renal',
  RESPIRATORY = 'Respiratory',
  RHEUMATOLOGY = 'Rheumatology',
  SEXYAL_HEALTH = 'Sexual and reproductive health',
  TRAUMA_SURGERY = 'Trauma and orthopaedic surgery',
  TROPICAL_MEDECINE = 'Tropical medicine',
  UROLOGY = 'Urology',
  VASCULAR_SURGERY = 'Vascular surgery',
  OTHER = 'Other',
}
