import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { AppDispatch, AppRootStateType } from 'state';

export type RejectValue = {
  data: Error | AxiosError<unknown>;
  globalError: boolean;
};

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: AppRootStateType;
  dispatch: AppDispatch;
  rejectValue: null | RejectValue;
}>();
