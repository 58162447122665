import {
  UserType,
  editUserRequest,
  getOneUserRequest,
  getUsersRequest,
  deleteUserRequest,
  blockedUserRequest,
  editManagerRequest,
  EditManagerType,
} from 'api';
import { UserStatus } from 'enums';
import { authActions, usersActions } from 'state';
import { checkIsGoodStatus, createAppAsyncThunk } from 'utils';

const getUsers = createAppAsyncThunk<any>(
  'user/get-users',
  async (arg, { dispatch, getState }) => {
    const { userSettings } = getState().users;

    try {
      dispatch(authActions.setLoading({ isLoading: true }));

      const res = await getUsersRequest(userSettings);

      dispatch(usersActions.setUsers(res?.data?.users));
      dispatch(
        usersActions.setUserSettings({
          ...userSettings,
          totalCount: res?.data?.totalCount,
        }),
      );
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const getOneUser = createAppAsyncThunk<any, { userId: string }>(
  'user/get-one-user',
  async ({ userId }, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));

      const res = await getOneUserRequest(userId);

      dispatch(usersActions.setCertainUser(res?.data));
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const editUser = createAppAsyncThunk<
  any,
  UserType & { setIsEditMode: (isEditMode: boolean) => void }
>('user/edit-user', async ({ setIsEditMode, ...arg }, { dispatch }) => {
  try {
    dispatch(authActions.setLoading({ isLoading: true }));
    const res = await editUserRequest(arg);

    if (checkIsGoodStatus(res?.status)) {
      setIsEditMode(false);
    }
  } finally {
    dispatch(authActions.setLoading({ isLoading: false }));
  }
});

const deleteUser = createAppAsyncThunk<any, { id: string }>(
  'user/delete-user',
  async ({ id }, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));
      const res = await deleteUserRequest(id);

      if (checkIsGoodStatus(res?.status)) {
        dispatch(getUsers());
      }
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const blockedUser = createAppAsyncThunk<any, { id: string; status: UserStatus }>(
  'user/blocked-user',
  async ({ id, status }, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));
      const res = await blockedUserRequest(id, status);

      if (checkIsGoodStatus(res?.status)) {
        dispatch(getUsers());
      }
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const editManager = createAppAsyncThunk<
  any,
  EditManagerType & { setIsEditMode: (isEditMode: boolean) => void }
>('user/edit-manager', async ({ setIsEditMode, ...arg }, { dispatch }) => {
  try {
    dispatch(authActions.setLoading({ isLoading: true }));
    const res = await editManagerRequest(arg);

    if (checkIsGoodStatus(res?.status)) {
      setIsEditMode(false);
    }
  } finally {
    dispatch(authActions.setLoading({ isLoading: false }));
  }
});

export const usersThunk = {
  getUsers,
  getOneUser,
  editUser,
  deleteUser,
  blockedUser,
  editManager,
};
