import React, { useEffect } from 'react';

import { toast, ToastContainer } from 'react-toastify';

import './Notification.scss';

import { useActions, useAppSelector } from 'hooks';
import { authActions, notificationSelect } from 'state';

import 'react-toastify/dist/ReactToastify.css';

export const Notification = () => {
  const { message, type } = useAppSelector(notificationSelect);

  const { setNotification } = useActions(authActions);
  const notify = () =>
    toast(<span className="notification">{message}</span>, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      type,
    });

  if (message !== null) {
    notify();
  }

  useEffect(() => {
    if (message !== null) {
      setTimeout(() => {
        setNotification({ notification: { message: null, type: undefined } });
      }, 3000);
    }
  }, [message]);

  return <ToastContainer />;
};
