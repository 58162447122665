import React, { MemoExoticComponent } from 'react';

import { routes } from '.';

import { SlotsIcon, CalendarIcon, UserIcon } from 'assets';

export enum Roles {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  STUDENT = 'STUDENT',
  DOCTOR = 'DOCTOR',
}

type MenuRouteType = {
  path: string;
  name: string;
  icon: MemoExoticComponent<(props: React.SVGProps<SVGSVGElement>) => React.JSX.Element>;
  visibleFor: Roles[];
};
export const MENU_ROUTES: MenuRouteType[] = [
  {
    path: routes.MAIN.USERS,
    name: 'Users',
    icon: UserIcon,
    visibleFor: [Roles.ADMIN],
  },
  {
    path: routes.MAIN.SLOTS,
    name: 'Slots',
    icon: SlotsIcon,
    visibleFor: [Roles.MANAGER],
  },
  {
    path: routes.MAIN.SESSIONS,
    name: 'Sessions',
    icon: CalendarIcon,
    visibleFor: [Roles.ADMIN],
  },
];
