import React, { ReactNode } from 'react';

import { clsx } from 'clsx';
import './ExternalDataCard.scss';

type ExternalDataCardType = {
  children: ReactNode;
  className?: string;
};
export const ExternalDataCard = ({ children, className }: ExternalDataCardType) => {
  return <section className={clsx('externalDataCard', className)}>{children}</section>;
};
