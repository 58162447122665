import { lazy } from 'react';

import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import { PrivateLayout, PublicLayout } from 'components';
import { routes } from 'enums';

const Sessions = lazy(() => import('pages/Sessions'));
const Login = lazy(() => import('pages/Login'));
const SignUp = lazy(() => import('pages/SignUp'));
const Users = lazy(() => import('pages/Users'));
const Profile = lazy(() => import('pages/Profile'));
const Slots = lazy(() => import('pages/Slots'));
const RestorePassword = lazy(() => import('pages/RestorePassword'));
const ConfirmationCode = lazy(() => import('pages/ConfirmationCode'));
const NewPassword = lazy(() => import('pages/NewPassword'));
const SuccessCreatePassword = lazy(() => import('pages/SuccessCreatePassword'));
const SlotProfile = lazy(() => import('pages/Slots/SlotProfile'));

const signUpRoutes: RouteObject[] = [
  { index: true, element: <SignUp /> },
  { path: routes.AUTH.CONFIRMATION_CODE, element: <ConfirmationCode /> },
  { path: routes.AUTH.NEW_PASSWORD, element: <NewPassword /> },
  { path: routes.AUTH.SUCCESS, element: <SuccessCreatePassword /> },
];

const restorePasswordRoutes: RouteObject[] = [
  { index: true, element: <RestorePassword /> },
  { path: routes.AUTH.CONFIRMATION_CODE, element: <ConfirmationCode /> },
  { path: routes.AUTH.NEW_PASSWORD, element: <NewPassword /> },
  { path: routes.AUTH.SUCCESS, element: <SuccessCreatePassword /> },
];

const publicRoutes: RouteObject[] = [
  { path: routes.AUTH.LOG_IN, element: <Login /> },
  { path: routes.AUTH.SIGN_UP, children: signUpRoutes },
  { path: routes.AUTH.RESTORE_PASSWORD, children: restorePasswordRoutes },
];

const privateRoutes: RouteObject[] = [
  { path: '*', element: <Navigate to={routes.MAIN.SESSIONS} /> },
  { path: routes.MAIN.SESSIONS, element: <Sessions /> },
  { path: routes.MAIN.USERS, element: <Users /> },
  { path: `${routes.MAIN.USERS}/:userId`, element: <Profile /> },
  { path: routes.MAIN.SLOTS, element: <Slots /> },
  { path: `${routes.MAIN.SLOTS}/:slotId`, element: <SlotProfile /> },
];

const router = createBrowserRouter([
  { element: <PrivateLayout />, children: privateRoutes },
  { element: <PublicLayout />, children: publicRoutes },
]);

export const Router = () => {
  return <RouterProvider router={router} />;
};
