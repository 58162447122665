import {
  addStudentToSlotRequest,
  deleteSlotRequest,
  EditUserInSlotType,
  editUserRequestByManager,
  getSlotsRequest,
} from 'api';
import { authActions, slotsActions } from 'state';
import { checkIsGoodStatus, createAppAsyncThunk } from 'utils';

const getSlots = createAppAsyncThunk<any>(
  'slots/get-slots',
  async (arg, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));

      const res = await getSlotsRequest();

      dispatch(slotsActions.setSlots(res.data));
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const editSlot = createAppAsyncThunk<
  any,
  EditUserInSlotType & { onCancelClick: () => void }
>('slots/edit-slot', async ({ onCancelClick, ...arg }, { dispatch }) => {
  try {
    dispatch(authActions.setLoading({ isLoading: true }));
    const res = await editUserRequestByManager(arg);

    if (checkIsGoodStatus(res?.status)) {
      onCancelClick();
    }
  } finally {
    dispatch(authActions.setLoading({ isLoading: false }));
  }
});

const addStudent = createAppAsyncThunk<any, { email: string; onCloseClick: () => void }>(
  'slots/add-student',
  async ({ email, onCloseClick }, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));
      const res = await addStudentToSlotRequest(email);

      if (checkIsGoodStatus(res?.status)) {
        onCloseClick();
        dispatch(getSlots());
      }
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

const clearSlot = createAppAsyncThunk<any, { email: string }>(
  'slots/clear-slot',
  async ({ email }, { dispatch }) => {
    try {
      dispatch(authActions.setLoading({ isLoading: true }));
      const res = await deleteSlotRequest(email);

      if (checkIsGoodStatus(res?.status)) {
        dispatch(getSlots());
      }
    } finally {
      dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

export const slotsThunk = {
  getSlots,
  clearSlot,
  addStudent,
  editSlot,
};
