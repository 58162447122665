import {
  ChangeEvent,
  ComponentProps,
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useId,
  useState,
} from 'react';

import { clsx } from 'clsx';

import { Typography } from '..';

import { EyeIcon, EyeCloseIcon, OpenLockIcon, CloseLockIcon } from 'assets';

import './TextField.scss';

export type TextFieldProps = {
  onValueChange?: (value: string) => void;
  containerProps?: ComponentProps<'div'>;
  labelProps?: ComponentProps<'label'>;
  errorMessage?: string;
  label?: string;
  icon?: ReactNode;
} & ComponentPropsWithoutRef<'input'>;

function getFinalType(type: ComponentProps<'input'>['type'], showPassword: boolean) {
  if (type === 'password' && showPassword) {
    return 'text';
  }

  return type;
}
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className,
      errorMessage,
      placeholder,
      type,
      containerProps,
      labelProps,
      label,
      onChange,
      onValueChange,
      icon,
      ...restProps
    },
    ref,
  ) => {
    const id = useId();
    const [showPassword, setShowPassword] = useState(false);

    const isShowPasswordButtonShown = type === 'password';

    const finalType = getFinalType(type, showPassword);

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
      onChange?.(e);
      onValueChange?.(e.target.value);
    }

    return (
      <div className={clsx('textField', className)}>
        {label && (
          <Typography
            variant="h3"
            as="label"
            className="textField_label"
            htmlFor={`${id}-input`}
          >
            {label}
          </Typography>
        )}
        <div className="textField_box">
          {isShowPasswordButtonShown &&
            (showPassword ? (
              <OpenLockIcon className="textField_box_icon" />
            ) : (
              <CloseLockIcon className="textField_box_icon" />
            ))}
          {icon && <div className="textField_box_icon">{icon}</div>}

          <input
            className={clsx(
              'textField_box_input',
              !!errorMessage && 'textField_box_input_error',
            )}
            placeholder={placeholder}
            ref={ref}
            type={finalType}
            onChange={handleChange}
            id={`${id}-input`}
            aria-describedby={`${id}-error-message`}
            {...restProps}
          />
          {isShowPasswordButtonShown && (
            <button
              className="textField_box_showPassword"
              type="button"
              onClick={() => setShowPassword(prev => !prev)}
            >
              {showPassword ? (
                <EyeIcon className="textField_box_showPassword_icon" />
              ) : (
                <EyeCloseIcon className="textField_box_showPassword_icon" />
              )}
            </button>
          )}
        </div>
        {errorMessage && (
          <Typography
            variant="p3"
            className="textField_error"
            aria-live="polite"
            id={`${id}-error-message`}
          >
            {errorMessage}
          </Typography>
        )}
      </div>
    );
  },
);
