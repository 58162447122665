import { SVGProps, memo } from 'react';

export const EyeIcon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M3.912 13.9224C4.7304 9.9924 8.1516 7.2 12 7.2C15.8472 7.2 19.2684 9.9924 20.088 13.9224C20.1205 14.0783 20.2135 14.215 20.3468 14.3023C20.48 14.3896 20.6425 14.4205 20.7984 14.388C20.9543 14.3555 21.091 14.2625 21.1783 14.1292C21.2656 13.996 21.2965 13.8335 21.264 13.6776C20.3316 9.2076 16.4316 6 12 6C7.5684 6 3.6684 9.2076 2.736 13.6776C2.70353 13.8335 2.73435 13.996 2.82167 14.1292C2.90898 14.2625 3.04565 14.3555 3.2016 14.388C3.35754 14.4205 3.52 14.3896 3.65322 14.3023C3.78645 14.215 3.87953 14.0783 3.912 13.9224ZM12 9.6C10.8861 9.6 9.8178 10.0425 9.03015 10.8302C8.2425 11.6178 7.8 12.6861 7.8 13.8C7.8 14.9139 8.2425 15.9822 9.03015 16.7698C9.8178 17.5575 10.8861 18 12 18C13.1139 18 14.1822 17.5575 14.9698 16.7698C15.7575 15.9822 16.2 14.9139 16.2 13.8C16.2 12.6861 15.7575 11.6178 14.9698 10.8302C14.1822 10.0425 13.1139 9.6 12 9.6ZM9 13.8C9 13.0044 9.31607 12.2413 9.87868 11.6787C10.4413 11.1161 11.2043 10.8 12 10.8C12.7956 10.8 13.5587 11.1161 14.1213 11.6787C14.6839 12.2413 15 13.0044 15 13.8C15 14.5956 14.6839 15.3587 14.1213 15.9213C13.5587 16.4839 12.7956 16.8 12 16.8C11.2043 16.8 10.4413 16.4839 9.87868 15.9213C9.31607 15.3587 9 14.5956 9 13.8Z"
      fill={props.fill}
    />
  </svg>
));
