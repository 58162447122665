import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { clsx } from 'clsx';
import './Button.scss';

type ButtonProps = {
  variant?: 'btn1' | 'btn2' | 'btn3';
} & ComponentPropsWithoutRef<'button'>;

export const Button = forwardRef<ElementRef<'button'>, ButtonProps>(
  ({ variant = 'btn1', className, ...rest }, ref) => {
    return (
      <button type="button" className={clsx(variant, className)} {...rest} ref={ref} />
    );
  },
);
