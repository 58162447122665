import { SVGProps, memo } from 'react';

type CircleCheckboxIconIconType = {
  checked?: boolean;
};
export const CircleCheckboxIcon = memo(
  ({
    checked = false,
    ...props
  }: SVGProps<SVGSVGElement> & CircleCheckboxIconIconType) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill="#ECF1FF" />
      <circle
        cx="8"
        cy="8"
        r="7.75"
        stroke="#0B365B"
        strokeOpacity="0.1"
        strokeWidth="0.5"
      />
      {checked && <circle cx="8" cy="8" r="5" fill="#5870BB" />}
    </svg>
  ),
);
