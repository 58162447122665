import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserType } from 'api';
import { NotificationType } from 'common';
import { Roles } from 'enums';
// import { authThunk } from 'state/thunks';

const initialState = {
  initialized: false as boolean,
  accessToken: '' as string,
  notification: { message: null, type: undefined } as NotificationType,
  isLoading: false,
  userInfo: {} as UserType,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setNotification: (
      state,
      action: PayloadAction<{ notification: NotificationType }>,
    ) => {
      state.notification = action.payload.notification;
    },
    setInitialized: state => {
      state.initialized = true;
    },
    setLoading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoading = action.payload.isLoading;
    },
    setToken: (state, action: PayloadAction<{ accessToken: string }>) => {
      state.accessToken = action.payload.accessToken;
    },
    setUserInfo: (state, action: PayloadAction<UserType>) => {
      state.userInfo = action.payload;
    },
    setUserRole: (state, action: PayloadAction<{ role: Roles }>) => {
      state.userInfo.role = action.payload.role;
    },
    setUserEmail: (state, action: PayloadAction<{ email: string }>) => {
      state.userInfo.email = action.payload.email;
    },
    setLogOut: () => initialState,
  },
  /* extraReducers: builder => {
    builder.addCase(authThunk.singUp.fulfilled, (state, action) => {
      state.notification = {
        message: 'Confirmation code sent successfully, check your email',
        type: 'success',
      };
    });
    builder.addCase(authThunk.singUp.rejected, (state, action) => {
      state.notification = {
        message: 'Confirmation code sent successfully, check your email',
        type: 'success',
      };
    });
  }, */
});

export const authActions = slice.actions;

export const authReducer = slice.reducer;
