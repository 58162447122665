import React from 'react';

import './Loader.scss';

import { LoaderIcon } from 'assets';
import { useAppSelector } from 'hooks';
import { isLoadingSelect } from 'state';

export const Loader = () => {
  const isLoading = useAppSelector(isLoadingSelect);

  return isLoading ? <LoaderIcon className="loader" /> : null;
};
