import React, { useMemo } from 'react';

import './Sidebar.scss';
import { clsx } from 'clsx';
import { NavLink, useNavigate } from 'react-router-dom';

import { BigLogoIcon, LogOutIcon } from 'assets';
import { routes } from 'enums';
import { useActions, useAppSelector } from 'hooks';
import { authThunk, userSelect } from 'state';
import { Typography } from 'ui-kit';
import { checkIsGoodStatus, getMenuRoutes } from 'utils';

export const Sidebar = () => {
  const user = useAppSelector(userSelect);
  const { logOut } = useActions(authThunk);
  const navigate = useNavigate();

  const onLogOutClick = () => {
    logOut({
      checkResponse: status => {
        if (checkIsGoodStatus(status)) {
          navigate(routes.AUTH.LOG_IN);
        }
      },
    });
  };

  const sideMenu = useMemo(() => {
    return getMenuRoutes(user?.role).map(menuRoute => {
      return (
        <NavLink to={menuRoute.path} key={menuRoute.path} className="sidebar_item">
          {({ isActive }) => (
            <div className={clsx('sidebar_item_box', isActive && 'activeLink')}>
              <div className="sidebar_item_box_row">
                <menuRoute.icon color={isActive ? '#0B365B' : '#FFFFFFB3'} />
                <Typography
                  variant="p3"
                  className={clsx(
                    'sidebar_item_box_row_link',
                    isActive && 'activeLinkColor',
                  )}
                >
                  {menuRoute.name}
                </Typography>
              </div>
            </div>
          )}
        </NavLink>
      );
    });
  }, [user?.role]);

  return (
    <nav className="sidebar">
      <div className="sidebar_logo">
        <BigLogoIcon />
      </div>
      {sideMenu}
      <div className="sidebar_item" onClick={onLogOutClick}>
        <LogOutIcon color="#FFFFFFB3" />
        <Typography variant="p3" className="sidebar_item_box_row_link">
          Log Out
        </Typography>
      </div>
    </nav>
  );
};
