import { SVGProps, memo } from 'react';

export const CalendarIcon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M8 4H7.2C6.08 4 5.52 4 5.092 4.218C4.71565 4.40969 4.40969 4.71565 4.218 5.092C4 5.52 4 6.08 4 7.2V8M8 4H16M8 4V2M4 8V16.8C4 17.92 4 18.48 4.218 18.908C4.40974 19.2843 4.71569 19.5903 5.092 19.782C5.519 20 6.079 20 7.197 20H16.803C17.921 20 18.48 20 18.907 19.782C19.284 19.59 19.59 19.284 19.782 18.908C20 18.48 20 17.922 20 16.804V8M4 8H20M16 4H16.8C17.92 4 18.48 4 18.907 4.218C19.284 4.41 19.59 4.715 19.782 5.092C20 5.519 20 6.079 20 7.197V8M16 4V2M16 16H16.002V16.002H16V16ZM12 16H12.002V16.002H12V16ZM8 16H8.002V16.002H8V16ZM16.002 12V12.002H16V12H16.002ZM12 12H12.002V12.002H12V12ZM8 12H8.002V12.002H8V12Z"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
