import React, { useEffect } from 'react';

import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Loader, Sidebar } from 'components';
import { Roles, routes } from 'enums';
import { useActions, useAppSelector } from 'hooks';
import { accessTokenSelect, authThunk, initializedSelect } from 'state';
import './PrivateLayout.scss';
import { getMenuRoutes } from 'utils';

export const PrivateLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { me } = useActions(authThunk);
  const token = useAppSelector(accessTokenSelect);
  const initialized = useAppSelector(initializedSelect);

  useEffect(() => {
    me({
      checkResponse: (role: Roles) => {
        const isAvailablePath = getMenuRoutes(role)
          .map(route => route?.path)
          .some(item => location.pathname.includes(item));

        if (!isAvailablePath && role) {
          navigate(getMenuRoutes(role)[0]?.path);
        }
      },
    });
  }, []);

  if (!initialized) {
    return <Loader />;
  }

  return token ? (
    <div className="privateLayout">
      <Sidebar />

      <Outlet />
    </div>
  ) : (
    <Navigate to={routes.AUTH.LOG_IN} replace state={location} />
  );
};
