import { SVGProps, memo } from 'react';

export const SlotsIcon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M7.2001 3.60001C6.24532 3.60001 5.32964 3.97929 4.65451 4.65442C3.97938 5.32955 3.6001 6.24523 3.6001 7.20001V16.8C3.6001 17.7548 3.97938 18.6705 4.65451 19.3456C5.32964 20.0207 6.24532 20.4 7.2001 20.4H11.5201C11.3239 20.0165 11.1659 19.6145 11.0485 19.2H7.2001C6.56358 19.2 5.95313 18.9471 5.50304 18.4971C5.05295 18.047 4.8001 17.4365 4.8001 16.8V7.20001C4.8001 6.56349 5.05295 5.95304 5.50304 5.50295C5.95313 5.05286 6.56358 4.80001 7.2001 4.80001H16.8001C17.4366 4.80001 18.0471 5.05286 18.4972 5.50295C18.9472 5.95304 19.2001 6.56349 19.2001 7.20001V11.0484C19.6189 11.1672 20.0197 11.3244 20.4001 11.52V7.20001C20.4001 6.24523 20.0208 5.32955 19.3457 4.65442C18.6706 3.97929 17.7549 3.60001 16.8001 3.60001H7.2001ZM17.4001 22.8C18.8323 22.8 20.2058 22.2311 21.2185 21.2184C22.2312 20.2057 22.8001 18.8322 22.8001 17.4C22.8001 15.9678 22.2312 14.5943 21.2185 13.5816C20.2058 12.5689 18.8323 12 17.4001 12C15.9679 12 14.5944 12.5689 13.5817 13.5816C12.569 14.5943 12.0001 15.9678 12.0001 17.4C12.0001 18.8322 12.569 20.2057 13.5817 21.2184C14.5944 22.2311 15.9679 22.8 17.4001 22.8ZM17.4001 14.4C17.5592 14.4 17.7118 14.4632 17.8244 14.5757C17.9369 14.6883 18.0001 14.8409 18.0001 15V16.8H19.8001C19.9592 16.8 20.1118 16.8632 20.2244 16.9757C20.3369 17.0883 20.4001 17.2409 20.4001 17.4C20.4001 17.5591 20.3369 17.7117 20.2244 17.8243C20.1118 17.9368 19.9592 18 19.8001 18H18.0001V19.8C18.0001 19.9591 17.9369 20.1117 17.8244 20.2243C17.7118 20.3368 17.5592 20.4 17.4001 20.4C17.241 20.4 17.0884 20.3368 16.9758 20.2243C16.8633 20.1117 16.8001 19.9591 16.8001 19.8V18H15.0001C14.841 18 14.6884 17.9368 14.5758 17.8243C14.4633 17.7117 14.4001 17.5591 14.4001 17.4C14.4001 17.2409 14.4633 17.0883 14.5758 16.9757C14.6884 16.8632 14.841 16.8 15.0001 16.8H16.8001V15C16.8001 14.8409 16.8633 14.6883 16.9758 14.5757C17.0884 14.4632 17.241 14.4 17.4001 14.4Z"
      fill={props.color}
    />
  </svg>
));
