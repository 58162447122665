import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';

import { authReducer, sessionsReducer, usersReducer, slotsReducer } from './reducers';

const appReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  sessions: sessionsReducer,
  slots: slotsReducer,
});

const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === 'logout/LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: reducerProxy,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: (process.env.NODE_ENV || '').trim() !== 'production',
});

export type AppRootStateType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
