import React, { forwardRef, ReactNode } from 'react';

import './ThreeDots.scss';
import { clsx } from 'clsx';

type ThreeDotsType = {
  isComponentVisible: boolean;
  setIsComponentVisible: (isComponentVisible: boolean) => void;
  children?: ReactNode;
  className?: string;
};

export const ThreeDots = forwardRef<HTMLElement, ThreeDotsType>(
  ({ children, className, isComponentVisible, setIsComponentVisible }, ref) => {
    const onThreeDotsClick = () => {
      setIsComponentVisible(true);
    };

    return (
      <div className={clsx('dots', className)} onClick={onThreeDotsClick}>
        <div className="dots_container">
          <div className="dots_container_dot" />
          <div className="dots_container_dot" />
          <div className="dots_container_dot" />
        </div>
        {isComponentVisible && (
          <section className="dots_popUp" ref={ref}>
            <div className="dots_popUp_box">
              <div className="dots_popUp_box_child">{children}</div>
            </div>
          </section>
        )}
      </div>
    );
  },
);
