import { SVGProps, memo } from 'react';

export const LogOutIcon = memo((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M14.7692 8.46154V6.73077C14.7692 6.27174 14.5869 5.83151 14.2623 5.50693C13.9377 5.18235 13.4975 5 13.0385 5H4.73077C4.27174 5 3.83151 5.18235 3.50693 5.50693C3.18235 5.83151 3 6.27174 3 6.73077V17.1154C3 17.5744 3.18235 18.0146 3.50693 18.3392C3.83151 18.6638 4.27174 18.8462 4.73077 18.8462H13.0385C13.4975 18.8462 13.9377 18.6638 14.2623 18.3392C14.5869 18.0146 14.7692 17.5744 14.7692 17.1154V15.3846M17.5385 8.46154L21 11.9231M21 11.9231L17.5385 15.3846M21 11.9231H9.1875"
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
