import { UserType } from 'api';
import { NotificationType } from 'common';
import { AppRootStateType } from 'state';

export const accessTokenSelect = (state: AppRootStateType) => state.auth.accessToken;
export const initializedSelect = (state: AppRootStateType) => state.auth.initialized;
export const notificationSelect = (state: AppRootStateType): NotificationType =>
  state.auth.notification;
export const isLoadingSelect = (state: AppRootStateType) => state.auth.isLoading;
export const userSelect = (state: AppRootStateType): UserType => state.auth.userInfo;
