import React from 'react';

import { Outlet } from 'react-router-dom';

import { BigLogoIcon } from 'assets';
import './PublicLayout.scss';

export const PublicLayout = () => {
  return (
    <section className="publicLayout">
      <div className="publicLayout_logo">
        <BigLogoIcon />
      </div>

      <div className="publicLayout_child">
        <Outlet />
      </div>
    </section>
  );
};
