import { AxiosResponse } from 'axios';

import {
  CommonResponseType,
  EditManagerType,
  UsersResponseType,
  UserType,
} from 'api/authApi';
import { EditUserInSlotType, UsersInSlotType } from 'api/slotsApi';
import { SettingsType } from 'common';
import { authServiceClient } from 'config';
import { UserStatus } from 'enums';

export const getUsersRequest = (params: SettingsType) => {
  return authServiceClient.get<
    UsersResponseType,
    AxiosResponse<UsersResponseType>,
    SettingsType
  >(`/user/all-users`, { params: { ...params, totalCount: undefined } });
};

export const getOneUserRequest = (userId: string) => {
  return authServiceClient.get<UserType, AxiosResponse<UserType>, { userId: string }>(
    `/user/user-id`,
    { params: { userId } },
  );
};

export const editUserRequest = (data: UserType) => {
  return authServiceClient.patch<UserType, AxiosResponse<UserType>, UserType>(
    `/user/update`,
    data,
  );
};

export const editManagerRequest = (data: EditManagerType) => {
  return authServiceClient.post<UserType, AxiosResponse<UserType>, EditManagerType>(
    `/manager-slot/create`,
    data,
  );
};

export const editUserRequestByManager = (data: EditUserInSlotType) => {
  return authServiceClient.patch<
    UsersInSlotType,
    AxiosResponse<UsersInSlotType>,
    EditUserInSlotType & { department: string }
  >(`/user/update`, { ...data, department: 'AAA' });
};

export const deleteUserRequest = (id: string) => {
  return authServiceClient.delete<
    CommonResponseType,
    AxiosResponse<CommonResponseType>,
    { id: string }
  >(`/auth/delete`, { params: { id } });
};

export const blockedUserRequest = (id: string, status: UserStatus) => {
  return authServiceClient.patch<
    UserType,
    AxiosResponse<UserType>,
    { id: string; status: UserStatus }
  >(`/user/change-status`, { id, status });
};
