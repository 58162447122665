import React from 'react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';

import { App } from 'app';
import { store } from 'state';

import './index.scss';

dayjs.extend(duration);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
