import axios, { AxiosError } from 'axios';

import { refreshTokenRequest } from 'api';
import { ApiPath, LsKeys } from 'enums';
import { authActions, authThunk, store } from 'state';

export const authServiceClient = axios.create({
  baseURL: ApiPath.auth,
  timeout: 30 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

authServiceClient.interceptors.request.use(
  config => {
    const { accessToken } = store.getState().auth;

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

authServiceClient.interceptors.response.use(
  response => response,
  async e => {
    const errorMsg = extractErrorMessage(e);
    const originalConfig = e.config;

    if (originalConfig.url !== '/auth/sign-in' && e.response) {
      if (originalConfig.url === '/auth/refresh' && e.response.status === 401) {
        return store.dispatch(authThunk.logOut({}));
      }
      if (e.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const data = JSON.parse(localStorage.getItem(LsKeys.TOKEN_INFO)!);

          if (data?.refreshToken) {
            const res = await refreshTokenRequest(data?.refreshToken);

            if (res?.data) {
              localStorage.setItem(
                LsKeys.TOKEN_INFO,
                JSON.stringify({ refreshToken: res?.data?.refreshToken }),
              );

              store.dispatch(
                authActions.setToken({ accessToken: res?.data?.accessToken }),
              );
              originalConfig.headers.Authorization = `Bearer ${res?.data?.accessToken}`;
            }
          }

          return authServiceClient(originalConfig);
        } catch (error) {
          return Promise.reject(error);
        }
      }
      if (e.response.status === 401 && originalConfig._retry) {
        return store.dispatch(authThunk.logOut({}));
      }
    }
    store.dispatch(
      authActions.setNotification({ notification: { message: errorMsg, type: 'error' } }),
    );
  },
);

export const extractErrorMessage = (e: Error | AxiosError | unknown): string | null => {
  if (axios.isCancel(e)) {
    return null;
  }
  if (axios.isAxiosError(e)) {
    const statusCode = e.response?.status;

    if (statusCode) {
      if (e.response?.data?.message === 'Wrong refresh token') {
        return null;
      }
      if (e.response?.data?.message === 'Incorrect password') {
        return 'Users with this credentials not found!';
      }

      return e.response?.data?.message;
    }
  } else if (e instanceof Error && e?.message) {
    return e.message;
  }

  return 'Something went wrong';
};
